//SignUp.js
import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate, Link } from 'react-router-dom';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [age, setAge] = useState('');
  const [state, setState] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const stateOptions = [
    'AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS',
    'KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY',
    'NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV',
    'WI','WY'
  ];

  const restrictedStates = ['WA','ID','NV'];

  const handleSignup = async () => {
    // Validate inputs before attempting sign up
    if (!email || !password || !age || !state) {
      setMessage('Please fill out all fields.');
      return;
    }

    const ageNumber = parseInt(age, 10);
    if (isNaN(ageNumber) || ageNumber < 18) {
      setMessage('You must be at least 18 years old to sign up.');
      return;
    }

    if (!stateOptions.includes(state)) {
      setMessage('Please select a valid U.S. state.');
      return;
    }

    if (restrictedStates.includes(state)) {
      setMessage("Sorry, state laws don't allow access to Sweepstakes casinos in your state.");
      return;
    }

    // Attempt sign-up
    const { data: { user, session }, error } = await supabase.auth.signUp({ email, password });
    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      if (session) {
        // User has a session, navigate directly to dashboard
        navigate('/dashboard');
      } else {
        // Likely need email confirmation
        setMessage('Sign-up successful! Please check your email to verify your account.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-indigo-500 to-cyan-500 flex flex-col">
      <header className="flex justify-end items-center p-6 text-white">
        <div className="space-x-4">
          <Link to="/login" className="hover:text-gray-200">Log In</Link>
          <Link to="/" className="hover:text-gray-200">Home</Link>
        </div>
      </header>

      <main className="flex-grow flex flex-col justify-center items-center px-4">
        <div className="bg-white max-w-md w-full rounded shadow p-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Create Your Account</h1>
          <p className="text-gray-600 mb-6">Sign up and start tracking your daily casino rewards!</p>

          {message && <div className="mb-4 text-red-600">{message}</div>}

          <input
            className="border border-gray-300 rounded w-full p-2 mb-3"
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            className="border border-gray-300 rounded w-full p-2 mb-3"
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          <input
            className="border border-gray-300 rounded w-full p-2 mb-3"
            type="number"
            placeholder="Age"
            value={age}
            onChange={e => setAge(e.target.value)}
          />

          <select
            className="border border-gray-300 rounded w-full p-2 mb-6"
            value={state}
            onChange={e => setState(e.target.value)}
          >
            <option value="">Select State</option>
            {stateOptions.map(st => (
              <option key={st} value={st}>{st}</option>
            ))}
          </select>

          <button
            className="bg-indigo-600 text-white w-full py-2 rounded hover:bg-indigo-700 transition font-semibold"
            onClick={handleSignup}
          >
            Sign Up
          </button>

          <div className="text-center mt-4 text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="text-indigo-600 hover:text-indigo-700">Log In</Link>
          </div>

          <div className="text-center mt-4">
            <Link to="/dashboard" className="text-indigo-600 underline hover:text-indigo-700">
              Continue without an account
            </Link>
          </div>
        </div>
      </main>

    </div>
  );
}

export default Signup;