import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      setMessage('Please fill out both fields.');
      return;
    }

    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage('Login successful!');
      setTimeout(() => navigate('/dashboard'), 1500);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-indigo-500 to-cyan-500 flex flex-col">
      <header className="flex justify-end items-center p-6 text-white">
        <div className="space-x-4">
          <Link to="/signup" className="hover:text-gray-200">Sign Up</Link>
          <Link to="/" className="hover:text-gray-200">Home</Link>
        </div>
      </header>

      <main className="flex-grow flex flex-col justify-center items-center px-4">
        <div className="bg-white max-w-md w-full rounded shadow p-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Welcome Back</h1>
          <p className="text-gray-600 mb-6">Log in to access your personalized dashboard.</p>

          {message && (
            <div className={`mb-4 ${message === 'Login successful!' ? 'text-green-600' : 'text-red-600'}`}>
              {message}
            </div>
          )}

          <input
            className="border border-gray-300 rounded w-full p-2 mb-3"
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            className="border border-gray-300 rounded w-full p-2 mb-6"
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          <button
            className="bg-indigo-600 text-white w-full py-2 rounded hover:bg-indigo-700 transition font-semibold"
            onClick={handleLogin}
          >
            Log In
          </button>

          <div className="text-center mt-4 text-sm text-gray-600">
            Don’t have an account? <Link to="/signup" className="text-indigo-600 hover:text-indigo-700">Sign Up</Link>
          </div>
        </div>
      </main>

    </div>
  );
}

export default Login;
