import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';


function computeNextAvailable(last_collected, reset_type, reset_value) {
  if (!last_collected) return null;
  const last = new Date(last_collected);

  if (reset_type === 'hourly') {
    const hours = parseInt(reset_value, 10);
    return new Date(last.getTime() + hours * 3600 * 1000);
  } else if (reset_type === 'specific_time') {
    const [hh, mm, ss] = reset_value.split(':').map(Number);
    const year = last.getUTCFullYear();
    const month = last.getUTCMonth();
    const date = last.getUTCDate();

    const dailyResetUTC = new Date(Date.UTC(year, month, date, hh, mm, ss));
    if (last < dailyResetUTC) {
      return dailyResetUTC;
    } else {
      const tomorrowUTC = new Date(dailyResetUTC);
      tomorrowUTC.setUTCDate(tomorrowUTC.getUTCDate() + 1);
      return tomorrowUTC;
    }
  }

  return null;
}

function formatTimeLeft(next_available) {
  if (!next_available) return 'Ready';
  const now = new Date();
  const diffMs = next_available - now;
  if (diffMs <= 0) return 'Ready';

  const totalMinutes = Math.floor(diffMs / 60000);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
}

function timeInMinutesForStatus(entry) {
  const { casinos, last_collected } = entry;
  const nextAvail = computeNextAvailable(last_collected, casinos.reset_type, casinos.reset_value);
  if (!nextAvail) return 0;
  const diffMs = nextAvail - new Date();
  return diffMs <= 0 ? 0 : Math.floor(diffMs / 60000);
}

function getExpectedTotalReward(casino) {
  const daily = casino.expected_daily_reward || 0;
  const rtpFraction = (casino.rtp || 100) / 100;
  return daily * rtpFraction;
}

function sortEntries(entries, sortColumn, sortOrder) {
  const sorted = [...entries];
  sorted.sort((a, b) => {
    let valA, valB;
    const casinoA = a.casinos;
    const casinoB = b.casinos;

    switch (sortColumn) {
      case 'casino':
        valA = casinoA.name.toLowerCase();
        valB = casinoB.name.toLowerCase();
        return valA.localeCompare(valB);
      case 'status':
        valA = timeInMinutesForStatus(a);
        valB = timeInMinutesForStatus(b);
        return valA - valB;
      case 'expected_total_reward':
        valA = getExpectedTotalReward(casinoA);
        valB = getExpectedTotalReward(casinoB);
        return valA - valB;
      case 'last_collected':
        valA = a.last_collected ? new Date(a.last_collected).getTime() : 0;
        valB = b.last_collected ? new Date(b.last_collected).getTime() : 0;
        return valB - valA;
      case 'total_earned':
        valA = a.user_total_payout || 0;
        valB = b.user_total_payout || 0;
        return valA - valB;
      case 'total_spent':
        valA = a.user_total_spent || 0;
        valB = b.user_total_spent || 0;
        return valA - valB;
      case 'community_rank':
        valA = casinoA.community_rank || 0;
        valB = casinoB.community_rank || 0;
        return valA - valB;
      default:
        return 0;
    }
  });

  if (sortOrder === 'desc') sorted.reverse();
  return sorted;
}



export default function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Tutorial state
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);

  const [sortColumn, setSortColumn] = useState('casino');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const [strategyCasino, setStrategyCasino] = useState(null);
  const [timerTick, setTimerTick] = useState(0);

  // Resources panel state
  const [showResourcesPanel, setShowResourcesPanel] = useState(false);

  // Problem gambling popup state
  const [showProblemGamblingPopup, setShowProblemGamblingPopup] = useState(false);

  // Disclosure popup state
  const [showDisclosurePopup, setShowDisclosurePopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isVisitor, setIsVisitor] = useState(false);

  useEffect(() => {
    loadData();
  }, [navigate]);

  // Update every minute to refresh countdown
  useEffect(() => {
    const interval = setInterval(() => {
      setTimerTick(t => t + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  async function loadData(reason = 'login') {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) console.error(userError);

    let currentUser = user;
    if (!currentUser) {
      // Visitor mode
      setIsVisitor(true);
    }

    const { data: allCasinos, error: casinosError } = await supabase
      .from('casinos')
      .select('*');
    if (casinosError) console.error(casinosError);

    let mergedData = [];
    if (currentUser) {
      const { data: userCasinos, error: userCasinosError } = await supabase
        .from('casinos_users')
        .select('*')
        .eq('user_id', currentUser.id);
      if (userCasinosError) console.error(userCasinosError);

      mergedData = allCasinos.map(casino => {
        const uc = userCasinos?.find(u => u.casino_id === casino.id);
        return {
          user_id: currentUser.id,
          casino_id: casino.id,
          last_collected: uc?.last_collected || null,
          user_total_payout: uc?.user_total_payout || 0,
          user_total_spent: uc?.user_total_spent || 0,
          user_vote: uc?.user_vote || 0,
          casinos: casino
        };
      });

      const totalSpent = mergedData.reduce((sum, entry) => sum + (entry.user_total_spent || 0), 0);
      if (totalSpent >= 1000 && (reason=== 'login'|| reason==='spend')) {
        setShowProblemGamblingPopup(true);
      }
    } else {
      // Visitor mode: No user data, just show casinos with no user-specific fields
      mergedData = allCasinos.map(casino => ({
        user_id: null,
        casino_id: casino.id,
        last_collected: null,
        user_total_payout: 0,
        user_total_spent: 0,
        user_vote: 0,
        casinos: casino
      }));
    }

    setData(mergedData);
    setLoading(false);

    // Check tutorial completion for user mode only
    if (currentUser) {
      const tutorialCompleted = localStorage.getItem('tutorialCompleted');
      if (!tutorialCompleted) {
        setShowTutorial(true);
        setTutorialStep(0);
      }
    }
  }

  async function handleLogout() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error(error);
    } else {
      navigate('/login');
    }
  }

  function onSortChange(col) {
    if (col === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(col);
      setSortOrder('asc');
    }
  }

  async function handleCollected(userCasino) {
    if (isVisitor) {
      alert("Please sign up or log in to collect bonuses.");
      return;
    }

    const { user_id, casino_id } = userCasino;
    const nowUTC = new Date().toISOString();
    const { error } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, last_collected: nowUTC }, { onConflict: 'user_id,casino_id' });
    if (error) {
      console.error(error);
      return;
    }
    await loadData('collect');
  }

  async function handleCashoutSubmit(userCasino, amount) {
    if (isVisitor) {
      alert("Please sign up or log in to record cashouts.");
      return;
    }

    const { user_id, casino_id, user_total_payout } = userCasino;
    const newTotal = (user_total_payout || 0) + parseFloat(amount);
    const { error } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, user_total_payout: newTotal }, { onConflict: 'user_id,casino_id' });
    if (error) {
      console.error(error);
      return;
    }
    await loadData('cashout');
  }

  async function handleSpentSubmit(userCasino, amount) {
    if (isVisitor) {
      alert("Please sign up or log in to record spending.");
      return;
    }

    const { user_id, casino_id, user_total_spent } = userCasino;
    const newTotal = (user_total_spent || 0) + parseFloat(amount);
    const { error } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, user_total_spent: newTotal }, { onConflict: 'user_id,casino_id' });
    if (error) {
      console.error(error);
      return;
    }
    await loadData('spend');
  }

  async function handleVote(userCasino, newVote) {
    if (isVisitor) {
      alert("Please sign up or log in to vote.");
      return;
    }

    const { casinos, casino_id, user_id } = userCasino;
    const oldVote = userCasino.user_vote || 0;
    const oldRank = casinos.community_rank || 0;

    if (oldVote === newVote) {
      return;
    }

    let newRank = oldRank + (oldVote === 0 ? newVote : (newVote - oldVote));
    let { error: casinoError } = await supabase
      .from('casinos')
      .update({ community_rank: newRank })
      .eq('id', casino_id);
    if (casinoError) {
      console.error(casinoError);
      return;
    }

    let { error: userVoteError } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, user_vote: newVote }, { onConflict: 'user_id,casino_id' });
    if (userVoteError) {
      console.error(userVoteError);
      return;
    }
    await loadData('vote');
  }

  function closeStrategyModal() {
    setShowStrategyModal(false);
    setStrategyCasino(null);
  }

  const tutorialPages = [
    {
      title: "Welcome to Your Daily Bonus Dashboard",
      content: "Here you can track the status of your daily bonuses to maximize your free rewards!"
    },
    {
      title: "How to Use the Dashboard",
      content: "Each casino row shows when you can next collect a bonus. 'Ready' means you can collect now. Otherwise, it shows how long until next availability. Use the strategy for maximization of profit."
    },
    {
      title: "Getting Started",
      content: (
        <div className="space-y-2">
          <p><strong>Step 1:</strong> Create a new email account to reduce spam.</p>
          <p><strong>Step 2:</strong> Sign up with each site offering daily rewards. This requires KYC verification. NEVER provide sensitive info like SSN or bank passwords, and do due diligence on each casino.</p>
          <p><strong>Step 3:</strong> Collect bonuses daily until reaching the minimum cashout threshold (usually $25-$100). Maximize earnings by choosing the best rtp strategy to convert your bonus into withdrawable funds. I describe the best rtp strategy I have used in the dashboard, however they may change.</p>
          <p><strong>Step 4:</strong> Cash out and record your earnings!</p>
          <p><strong>Step 5 (Optional):</strong> Some deals to purchase in game currency might seem profitable with churning, but can be risky and do not fall for it. Approach with caution and understand casino psychology before trying this incredibly slippery slope. Note that these are affiate links.</p>
        </div>
      )
    },
    {
      title: "Good Luck!",
      content: "You're all set. Enjoy maximizing your daily bonus rewards and have fun! Remember to play responsibly and that I do not endorse these casinos, they're just the ones I have used."
    }
  ];

  function handleTutorialNext() {
    if (tutorialStep < tutorialPages.length - 1) {
      setTutorialStep(s => s + 1);
    } else {
      localStorage.setItem('tutorialCompleted', 'true');
      setShowTutorial(false);
    }
  }

  const now = new Date();
  const sortedData = sortEntries(data, sortColumn, sortOrder);
  const totalEarned = data.reduce((sum, entry) => sum + (entry.user_total_payout || 0), 0);
  const totalAvailable = sortedData.reduce((sum, e) => {
    const nextAvail = computeNextAvailable(e.last_collected, e.casinos.reset_type, e.casinos.reset_value);
    const isReady = !nextAvail || nextAvail <= now;
    if (isReady) {
      return sum + getExpectedTotalReward(e.casinos);
    }
    return sum;
  }, 0);

  function handleResourcesClick() {
    setShowResourcesPanel(r => !r);
  }

  function onShowStrategy(casino) {
    setStrategyCasino(casino);
    setShowStrategyModal(true);
  }

  async function handleDeleteAccount() {
    setShowDeleteModal(false);
  
    // Get the current user to include user ID in the request if needed
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.error("No user logged in");
      return;
    }
  
    // Call your serverless function
    const response = await fetch('/api/deleteAccount', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: user.id })
    });
  
    if (response.ok) {
      // Successfully deleted on the server, now sign out locally
      await supabase.auth.signOut();
      navigate('/goodbye'); // or '/login'
    } else {
      console.error("Failed to delete account");
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4 text-gray-800 relative">
      <div className="bg-white p-4 rounded shadow-md mb-4 flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold mb-2">Your Dashboard</h1>
          {!isVisitor && (
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-4">
              <h2 className="text-xl font-semibold text-gray-700">Total Earned All Time: ${totalEarned.toFixed(2)}</h2>
              <h2 className="text-xl font-semibold text-gray-700">Total Available Now: ${totalAvailable.toFixed(2)}</h2>
            </div>
          )}
          {isVisitor && (
            <p className="text-gray-700 italic">Visitor Mode: Viewing casino info without login. Actions disabled.</p>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleResourcesClick}
            className="bg-indigo-600 text-white px-4 py-1 rounded hover:bg-indigo-700 transition font-semibold"
          >
            Resources
          </button>
          {!isVisitor && (
            <button
              onClick={handleLogout}
              className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
            >
              Logout
            </button>
          )}
        </div>
      </div>

      <UserCasinosTable
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortOrder}
        onSortChange={onSortChange}
        onShowStrategy={onShowStrategy}
        onCollected={handleCollected}
        onCashoutSubmit={handleCashoutSubmit}
        onSpentSubmit={handleSpentSubmit}
        onVote={handleVote}
        isVisitor={isVisitor}
      />

      {showStrategyModal && strategyCasino && (
        <StrategyModal casino={strategyCasino} onClose={() => setShowStrategyModal(false)} />
      )}

      {showTutorial && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full relative">
            <h2 className="text-2xl font-bold mb-4">{tutorialPages[tutorialStep].title}</h2>
            <div className="mb-6 text-gray-700">
              {tutorialPages[tutorialStep].content}
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleTutorialNext}
                className="bg-indigo-600 text-white px-4 py-1 rounded hover:bg-indigo-700 transition font-semibold"
              >
                {tutorialStep < tutorialPages.length - 1 ? 'Next' : 'Finish'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showProblemGamblingPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-2 text-gray-800">Recognize the Signs of Problem Gambling</h2>
            <p className="text-gray-700 mb-4">If you or someone you know might have a problem with gambling, please consider seeking help.</p>
            <a 
              href="https://www.helpguide.org/mental-health/addiction/gambling-addiction-and-problem-gambling.htm" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 underline mb-4 inline-block"
            >
              Learn more about problem gambling
            </a>
            <div className="flex justify-end">
              <button
                onClick={() => setShowProblemGamblingPopup(false)}
                className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showDisclosurePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-2 text-gray-1000">Disclosure</h2>
            <div className="text-gray-900 space-y-4">
              <p>Gambling, even just playing daily freebies, can turn into a slippery slope if you’re not careful.</p>
              <p>The idea is to have fun and take advantage of daily rewards and maybe earn a bit of extra cash without risking your own money. But if you do end up spending money, understand that some of these links are referral links where I earn money if you spend. I’m honestly hoping you don’t spend and just use the free bonuses.</p>
              <p>The strategies and the list of casinos are just what I personally use. I’m not endorsing or sponsoring them, so definitely do your own research.</p>
              <p>As for your data, your email is only ever used for your login—nothing else. I don’t collect personal data, I’ll never sell or share your data/email with anyone else. It’s just to display in the dashboard and help you keep track. Deleting your account deletes all data</p>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDisclosurePopup(false)}
                className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      




      {showResourcesPanel && (
        <div className="fixed top-20 right-4 bg-white border shadow-lg rounded p-4 z-50 w-64">
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold text-gray-800">Resources</h3>
            <button onClick={() => setShowResourcesPanel(false)} className="text-gray-500 hover:text-gray-700 font-bold">X</button>
          </div>
          <ul className="space-y-2 text-blue-600 underline">
            <li>
              <a href="https://www.newtraderu.com/2024/07/07/the-psychology-of-casinos-how-casinos-trick-you/" target="_blank" rel="noopener noreferrer">
                Casino Psychology
              </a>
            </li>
            <li>
              <a href="https://www.ncpgambling.org/help-treatment/about-the-national-problem-gambling-helpline/" target="_blank" rel="noopener noreferrer">
                National Problem Gambling Helpline
              </a>
            </li>
            <li>
              <a href="https://sccgmanagement.com/sccg-articles/2024/8/19/sweepstakes-casino-frequently-asked-questions/#:~:text=A%3A%20Sweepstakes%20Casinos%20use%20virtual,can%20be%20redeemed%20for%20prizes." target="_blank" rel="noopener noreferrer">
                Sweepstakes Casino – FAQ
              </a>
            </li>
             <li>
              <button 
                onClick={() => setShowDisclosurePopup(true)} 
                className="text-blue-600 underline text-left w-full"
              >
                Disclosure
              </button>
            </li>
            <li>
              <button
                onClick={() => setShowDeleteModal(true)} 
                className="text-blue-600 underline text-left w-full"
              >
                Delete Account
              </button>
            </li>
          </ul>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-2 text-gray-800">Delete Account</h2>
            <p className="text-gray-700 mb-4">
              Are you sure you want to delete your account and all associated data? This cannot be undone.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 text-gray-800 px-4 py-1 rounded hover:bg-gray-400 transition font-semibold"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAccount}
                className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function UserCasinosTable({
  data,
  sortColumn,
  sortOrder,
  onSortChange,
  onShowStrategy,
  onCollected,
  onCashoutSubmit,
  onSpentSubmit,
  onVote,
  isVisitor
}) {
  const renderHeader = (label, columnKey, sortable = true) => {
    const isActive = columnKey === sortColumn;
    const arrow = isActive ? (sortOrder === 'asc' ? '▲' : '▼') : '';
    const classes = `text-left p-2 font-semibold ${sortable ? 'cursor-pointer select-none' : ''}`;
    return (
      <th
        className={classes}
        onClick={sortable ? () => onSortChange(columnKey) : undefined}
      >
        {label} {arrow}
      </th>
    );
  };

  return (
    <div className="overflow-auto">
      <table className="min-w-full bg-white border rounded shadow-sm">
        <thead className="bg-gray-100 border-b">
          <tr>
            {renderHeader('Casino', 'casino')}
            {renderHeader('Status', 'status')}
            {renderHeader('Link', '', false)}
            {renderHeader('Collect', '', false)}
            {renderHeader('Expected Total Reward', 'expected_total_reward')}
            {renderHeader('Strategy', '', false)}
            {renderHeader('Last Collected', 'last_collected')}
            {renderHeader('Cash-out Value', '', false)}
            {renderHeader('Total Earned', 'total_earned')}
            {renderHeader('Spent', '', false)}
            {renderHeader('Total Spent', 'total_spent')}
            {renderHeader('Community Score', 'community_rank')}
          </tr>
        </thead>
        <tbody>
          {data.map((e, idx) => {
            const casino = e.casinos;
            const nextAvail = computeNextAvailable(e.last_collected, casino.reset_type, casino.reset_value);
            const displayStatus = formatTimeLeft(nextAvail);
            const expTotalReward = getExpectedTotalReward(casino).toFixed(2);

            return (
              <UserCasinoRow
                key={(e.user_id || 'visitor') + '-' + e.casino_id}
                userCasino={e}
                casino={casino}
                displayStatus={displayStatus}
                expTotalReward={expTotalReward}
                onCollected={onCollected}
                onCashoutSubmit={onCashoutSubmit}
                onSpentSubmit={onSpentSubmit}
                onShowStrategy={onShowStrategy}
                onVote={onVote}
                rowClassName={`${idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}
                isVisitor={isVisitor}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function UserCasinoRow({
  userCasino,
  casino,
  displayStatus,
  expTotalReward,
  onCollected,
  onCashoutSubmit,
  onSpentSubmit,
  onShowStrategy,
  onVote,
  rowClassName,
  isVisitor
}) {
  const [cashoutValue, setCashoutValue] = useState('');
  const [spentValue, setSpentValue] = useState('');

  const statusCell = displayStatus === 'Ready'
    ? <span className="text-green-700 font-bold whitespace-nowrap">{displayStatus}</span>
    : <span className="text-gray-700 font-bold whitespace-nowrap">{displayStatus}</span>;

  return (
    <tr className={`border-b ${rowClassName}`}>
      <td className="p-2 text-gray-800 whitespace-nowrap">{casino.name}</td>
      <td className="p-2 whitespace-nowrap">{statusCell}</td>
      <td className="p-2 whitespace-nowrap">
        <a href={casino.link} className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">
          Visit
        </a>
      </td>
      <td className="p-2 whitespace-nowrap">
        <button
          onClick={() => onCollected(userCasino)}
          className={`text-white px-4 py-1 rounded transition ${isVisitor ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}`}
          disabled={isVisitor}
        >
          Collect
        </button>
      </td>
      <td className="p-2 whitespace-nowrap">${expTotalReward}</td>
      <td className="p-2 text-blue-600 hover:underline cursor-pointer whitespace-nowrap" onClick={() => onShowStrategy(userCasino.casinos)}>View</td>
      <td className="p-2 whitespace-nowrap">{userCasino.last_collected ? new Date(userCasino.last_collected).toLocaleString() : 'Never'}</td>
      <td className="p-2 whitespace-nowrap">
        <input
          className={`border p-1 w-20 rounded ${isVisitor ? 'bg-gray-100' : ''}`}
          type="number"
          step="0.01"
          placeholder="0.00"
          value={cashoutValue}
          onChange={(e) => setCashoutValue(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && cashoutValue.trim() !== '') {
              await onCashoutSubmit(userCasino, cashoutValue.trim());
              setCashoutValue('');
            }
          }}
          disabled={isVisitor}
        />
      </td>
      <td className="p-2 whitespace-nowrap">${(userCasino.user_total_payout || 0).toFixed(2)}</td>
      <td className="p-2 whitespace-nowrap">
        <input
          className={`border p-1 w-20 rounded ${isVisitor ? 'bg-gray-100' : ''}`}
          type="number"
          step="0.01"
          placeholder="0.00"
          value={spentValue}
          onChange={(e) => setSpentValue(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && spentValue.trim() !== '') {
              await onSpentSubmit(userCasino, spentValue.trim());
              setSpentValue('');
            }
          }}
          disabled={isVisitor}
        />
      </td>
      <td className="p-2 whitespace-nowrap">${(userCasino.user_total_spent || 0).toFixed(2)}</td>
      <td className="p-2 flex items-center space-x-2 whitespace-nowrap">
        <button
          onClick={() => onVote(userCasino, +1)}
          className={`font-bold ${userCasino.user_vote === 1 ? 'text-green-600' : 'text-gray-600'} hover:text-green-700 transition ${isVisitor ? 'cursor-not-allowed opacity-50' : ''}`}
          disabled={isVisitor}
        >
          ▲
        </button>
        <span>{casino.community_rank || 0}</span>
        <button
          onClick={() => onVote(userCasino, -1)}
          className={`font-bold ${userCasino.user_vote === -1 ? 'text-red-600' : 'text-gray-600'} hover:text-red-700 transition ${isVisitor ? 'cursor-not-allowed opacity-50' : ''}`}
          disabled={isVisitor}
        >
          ▼
        </button>
      </td>
    </tr>
  );
}

function StrategyModal({ casino, onClose }) {
  const { where_to_collect, best_strategy, rtp, supports_crypto } = casino;

  const calcLink = best_strategy.toLowerCase() === 'black jack'
    ? 'https://outplayed.com/blackjack-strategy-calculator'
    : best_strategy.toLowerCase() === 'texas holdem'
      ? 'https://www.888poker.com/poker/poker-odds-calculator'
      : null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded max-w-md shadow-lg">
        <h2 className="font-bold text-xl mb-2 text-gray-800">Strategy for {casino.name}</h2>
        <p className="text-gray-700"><strong>How to find Reward:</strong> {where_to_collect}</p>
        <p className="text-gray-700"><strong>Best Strategy:</strong> {best_strategy}</p>
        <p className="text-gray-700"><strong>RTP of Best Strategy:</strong> {rtp}%</p>
        {supports_crypto && (
          <p className="text-gray-700">
            Supports crypto, I use Coinbase. Use my link for an extra $10 on top of up to $200 sign up bonus{" "}
            <a
              href="https://coinbase.com/join/MQZK23H?src=referral-link"
              className="text-blue-600 underline"
              target="_blank"
              rel="noreferrer"
            >
              Coinbase Referral
            </a>
          </p>
        )}
        {calcLink && (
          <p className="text-gray-700">Use this calculator for the best odds:{" "}
            <a
              href={calcLink}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 underline"
            >
              Calculator
            </a>
          </p>
        )}
        <button onClick={onClose} className="bg-indigo-600 text-white px-4 py-1 mt-4 rounded hover:bg-indigo-700 transition">
          Close
        </button>
      </div>
    </div>
  );
}