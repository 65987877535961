import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-indigo-500 to-cyan-500 flex flex-col">
      {/* Nav (if needed) */}
      <header className="flex justify-end items-center p-6">
        <div className="space-x-4">
          <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
          <Link to="/signup" className="text-white bg-indigo-800 px-4 py-2 rounded hover:bg-indigo-700 transition">
            Sign Up
          </Link>
        </div>
      </header>

      {/* Hero Section */}
      <main className="flex-grow flex flex-col justify-center items-center text-center px-4">
        <h1 className="text-4xl md:text-6xl font-extrabold text-white leading-tight">
          Maximize Your Daily Casino Rewards
        </h1>
        <p className="mt-4 text-lg md:text-2xl text-white/90 max-w-2xl">
          Dashboard to track, collect, and optimize your daily bonuses from sweepstake and social casinos
        </p>

        <div className="mt-8 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-4">
          <Link 
            to="/signup" 
            className="bg-white text-indigo-700 font-semibold px-8 py-3 rounded shadow hover:shadow-md hover:bg-gray-50 transition"
          >
            Sign Up
          </Link>
          <Link 
            to="/login" 
            className="bg-indigo-700 text-white font-semibold px-8 py-3 rounded shadow hover:shadow-md hover:bg-indigo-800 transition"
          >
            Log In
          </Link>
        </div>
        
        <div className="mt-4">
          <Link 
            to="/dashboard" 
            className="text-white underline hover:text-gray-200"
          >
            Continue without an account
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Home;